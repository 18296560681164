import React from "react";
import Layout from "../components/layout";

export default function Success() {
    return (
        <Layout>
            <div style={{ height: "40vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <h1 style={{ textAlign: "center" }}>Form Submitted Successfully...</h1>
            </div>
        </Layout>
    )
}